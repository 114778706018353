import React from 'react'
import Layout from '../components/layout-sub'
import SEO from '../components/seo'
import Container from '../components/container'
import {Link} from 'gatsby'

const successPage = props => {
  const {data, errors} = props
  if (errors) {
    return (
      <Layout>
        Error
      </Layout>
    )
  }

  return (
    <Layout>
      <SEO title='resume' />
      <Container>
        <section className='siteHero'>
          <h1 className='siteTitle'>Success!</h1>
          <h2 className='siteSubtitle'>Thank you for contacting us!</h2>
          <article className='contactLockup'>
            <Link className='cta' to='/'>Go back to Home</Link>
          </article>
        </section>
      </Container>
    </Layout>
  )
}

export default successPage
